<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet class="pa-4">
        <v-avatar class="mb-4" color="grey darken-1" size="64"
          ><img :src="avatar" alt="Avatar" srcset="" width="64"
        /></v-avatar>

        <h4>Pet dog</h4>
      </v-sheet>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="[icon, text, go] in links"
          :key="icon"
          link
          :to="go"
          class="text-left"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :to="go">{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>

      <img :src="logo" alt="Logo" srcset="" width="100" class="mt-10" />
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-text-field v-if="actionSearch"
            v-model="search"
            clearable
            flat
            solo
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>

      <v-btn icon @click="actionSearch = !actionSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-icon @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >mdi-invert-colors</v-icon
      >
      <v-toolbar-title></v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data: () => ({
    logo: require("@/assets/logo.png"),
    avatar: require("@/assets/avatar.jpg"),
    drawer: null,
    actionSearch: false,
    links: [
      ["mdi-home", "Home Page", "/time_line"],
      ["mdi-web", "Search", "/search"],
      ["mdi-mail", "Messages", "/messages"],
      ["mdi-account", "Account", "/account"],
      ["mdi-arrow-right", "Go Out", "/login"],
    ],
  }),
};
</script>
